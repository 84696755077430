import React, { useEffect } from "react";

type WelcomeMessageProps = {
  message: string;
  inputRef: React.RefObject<HTMLInputElement>;
};

const WelcomeMessage = (props: WelcomeMessageProps) => {
  const welcomeMessageRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.inputRef.current) {
      props.inputRef.current.disabled = true;
    }

    let index = 0;

    const typeText = setInterval(() => {
      if (welcomeMessageRef.current) {
        welcomeMessageRef.current.textContent = props.message.slice(0, index);
        index++;

        if (index > props.message.length) {
          clearInterval(typeText);

          if (props.inputRef.current) {
            props.inputRef.current.disabled = false;
            props.inputRef.current.focus();
          }
        }
      }
    }, 30);

    return () => {
      clearInterval(typeText);
    };

  }, [props.inputRef, props.message]);

  return (
    <div ref={welcomeMessageRef} className="terminal-welcome-message"></div>
  );
};

export default WelcomeMessage;
