import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import TerminalOutput from "./TerminalOutput";
import InputArea from "./InputArea";
import ErrorMessage from "./ErrorMessage";
import WelcomeMessage from "./WelcomeMessage";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

type TerminalProps = {
  terminalPrompt?: string;
  banner?: string;
  welcomeMessage?: string;
};
const Terminal = (props: TerminalProps) => {
  const { terminalPrompt = ">", banner, welcomeMessage } = props;
  const [output, setOutput] = useState<(string | JSX.Element)[]>([]);
  const [history, setHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(3);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);

  const scrollLastCommandTop = () => {
    scrollRef.current?.scrollIntoView();
  };

  useEffect(scrollLastCommandTop, [output]);

  const echoCommands = [
    "menu",
    "about",
    "blogposts",
    "blogcategories",
    "contact",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
  ] as const;
  type EchoCommand = (typeof echoCommands)[number];
  const utilityCommands = ["clear", "all", "cv"] as const;
  type UtilityCommand = (typeof utilityCommands)[number];
  const allCommands = [...echoCommands, ...utilityCommands] as const;
  type Command = (typeof allCommands)[number];

  function isEchoCommand(arg: string): arg is EchoCommand {
    return (echoCommands as ReadonlyArray<string>).includes(arg);
  }

  function isUtilityCommand(arg: string): arg is UtilityCommand {
    return (utilityCommands as ReadonlyArray<string>).includes(arg);
  }

  function isValidCommand(arg: string): arg is Command {
    return isEchoCommand(arg) || isUtilityCommand(arg);
  }

  // const glow = (text: string) => {
  //   return <span className="terminal-glow">{text}</span>;
  // };

  const commands: { [key in EchoCommand]: JSX.Element } = {
    menu: (
      <div>
        <p>
          Just type any of the commands below and press Enter to get some more info. <br />
          You can even type a few letters and press [tab] or '.' to
          autocomplete.
        </p>
        <dl>
          <dt>about</dt>
          <br />
          <dd>About Kieram</dd>
          <br />

          <dt>blogposts</dt>
          <br />
          <dd>Blog Posts</dd>
          <br />

          <dt>blogcategories</dt>
          <br />
          <dd>Blog Categories</dd>
          <br />

          <dt>contact</dt>
          <br />
          <dd>Get In Touch</dd>
        </dl>
      </div>
    ),

    about: (
      <div>
        <p>
          <dt>
            He's your Paramedic Papi, your Survival Sensei, your First Aid
            Führer.
          </dt>

          <p>
            Kieram Litchfield is the most watched first aid instructor on the
            internet, with over 500,000,000 views across social media.
          </p><br />

          <dt>Teacher</dt>

          <p>
            Kieram is one of the most popular first aid instructors on the
            planet, with over 500 million views accross social media. Teaching
            people who to survive from everything from nuclear fallout, scammers
            in NYC and gunshot wounds to the chest.
          </p><br />

          <dt>Author</dt>

          <p>
            Author of Instant Hero. A Simple Guide for Learning How to Save
            Lives & Confidently Handle Any Medical Emergency
          </p><br />

          <dt>Activist</dt>

          <p>
            Kieram has been working to spread the truth about drugs for 10 years
          </p><br />

          <dt>Volunteer</dt>

          <p>
            Kieram has volunteered thousands of hours at over 8 disasters world
            wide, from hurricanes in Japan to Tornados in Alabama.
          </p>
        </p>
      </div>
    ),

    contact: (
      <>
        <br /><dt>Instagram</dt>

        <p>
          <a href="https://www.instagram.com/kieram.litchfield/">
            https://www.instagram.com/kieram.litchfield/
          </a>
        </p><br />

        <dt>TikTok</dt>

        <p>
          <a href="https://www.tiktok.com/@kieram.litchfield">
            https://www.tiktok.com/@kieram.litchfield
          </a>
        </p><br />
        <dt>YouTube</dt>

        <p>
          <a href="https://www.youtube.com/KieramLitchfield">
            https://www.youtube.com/KieramLitchfield
          </a>
        </p><br />

        <dt>Twitter</dt>

        <p>
          <a href="https://twitter.com/KieramLitch">
            https://twitter.com/KieramLitch
          </a>
        </p>
      </>
    ),

    
    blogposts: (
      <p>
        <dt>1</dt>
        <dl>
          - The Dangers of Chasing Instant Pleasure: Analyzing Societal
          Consequences
        </dl>
        <dt>2</dt>
        <dl>
          - A Theoretical Society: Examining the Implications of Habitual
          Substance Use
        </dl>
        <dt>3</dt>
        <dl>
          - Evaluating Alcohol vs. THC Edibles: Assessing Societal and Health
          Implications
        </dl>
        <dt>4</dt>
        <dl>
          - Assessing the Drawbacks: An In-Depth Examination of Marijuana
          Smoking
        </dl>
        <dt>5</dt>
        <dl>
          - Challenging Assumptions: The Complex Relationship Between Marijuana
          Use and Habitual Consumption
        </dl>

        <dt>6</dt>
        <dl>- Let's ask Chat GPT: Does Marijuana make you less productive?</dl>
        <dt>7</dt>
        <dl>- Let's ask Chat GPT for a pro and anti argument for marijuana</dl>
        <dt>8</dt>
        <dl>- Marijuana is OK because it is natural?</dl>
        <dt>9</dt>
        <dl>- Let's ask Chat GPT's opinion of marijuana</dl>
        <dt>10</dt>
        <dl>- 5 forms of peer pressure that make people do drugs</dl>
        <dt>11</dt>
        <dl>- Drugged driving</dl>
        <dt>12</dt>
        <dl>- To be drugged</dl>
        <dt>13</dt>
        <dl>- Thoughts on gun control</dl>
      </p>
    ),

    blogcategories: (
      <>
        <br />


        {/* Alcohol  --------------------------------------------------------------------------------------------------------------*/}
        <dt>Alcohol</dt>
        <dl>
          <span className="glow">1</span> - The Dangers of Chasing Instant
          Pleasure: Analyzing Societal Consequences
        </dl>
        <dl>
          <span className="glow">2</span> - A Theoretical Society: Examining the
          Implications of Habitual Substance Use
        </dl>
        <dl>
          <span className="glow">3</span> - Evaluating Alcohol vs. THC Edibles:
          Assessing Societal and Health Implications
        </dl>
        <br />

       {/* DRUGS  --------------------------------------------------------------------------------------------------------------*/}
      
            <dt>Drugs</dt>
        <dl>
          <span className="glow">4</span> - Assessing the Drawbacks: An In-Depth
          Examination of Marijuana Smoking
        </dl>
        <dl>
          <span className="glow">5</span> - Challenging Assumptions: The Complex
          Relationship Between Marijuana Use and Habitual Consumption
        </dl>
        <dl>
          <span className="glow">6</span> - Let's ask Chat GPT: Does Marijuana
          make you less productive?
        </dl>
        <dl>
          <span className="glow">7</span> - Let's ask Chat GPT for a pro and
          anti argument for marijuana
        </dl>
        <dl>
          <span className="glow">8</span> - Marijuana is OK because it is
          natural?
        </dl>
        <dl>
          <span className="glow">9</span> - Let's ask Chat GPT's opinion of
          marijuana
        </dl>
        <dl>
          <span className="glow">10</span> - 5 forms of peer pressure that make
          people do drugs
        </dl>
        <dl>
          <span className="glow">11</span> - Drugged driving
        </dl>
        <dl>
          <span className="glow">12</span> - To be drugged
        </dl>


  {/* gun control  --------------------------------------------------------------------------------------------------------------*/}
<br />
  <dt>Gun Control</dt>
        
        <dl>
          <span className="glow">13</span> - Thoughts on gun control
        </dl>


      </>
    ),

    //----------  1  The Dangers of Chasing Instant Pleasure: Analyzing Societal Consequences

    1: (
      <p>
        <dt>
          The Dangers of Chasing Instant Pleasure: Analyzing Societal
          Consequences
        </dt>
        <dl>July 27, 2023</dl>
        <br />

        <dt>Introduction:</dt>

        <dl>
          In today's fast-paced world, the allure of immediate gratification is
          ever-present. People seek pleasure and stimulation through drugs,
          alcohol, porn, video games, and social media. While these sources of
          quick satisfaction may offer fleeting enjoyment, it is crucial to
          critically examine their long-term impact on society. This blog post
          explores the potential implications of a society where individuals
          increasingly depend on highly stimulating activities, taking a
          skeptical view of the overall well-being of such a community.
        </dl>

        <dt>1: Escapism and Reliance:</dt>

        <dl>
          The appeal of instant gratification lies in its ability to provide an
          easy escape from life's complexities and challenges. However, the risk
          of developing dependency on these quick fixes is significant, as
          individuals turn to them as coping mechanisms for stress and emotional
          struggles. This pattern of escapism might hinder the ability to
          confront real-life problems effectively, leading to an unhealthy cycle
          of dependency.
        </dl>

        <dt>2: Erosion of Real-Life Connections:</dt>

        <dl>
          As people immerse themselves in instant pleasure, genuine human
          connections may suffer. Excessive engagement with social media, video
          games, and other highly stimulating activities can limit face-to-face
          interactions, weakening the fabric of community bonds. This isolation
          and disconnection may contribute to a decline in meaningful
          relationships.
        </dl>

        <dt>3: Impact on Mental Well-Being:</dt>

        <dl>
          The pursuit of immediate pleasure can have adverse effects on mental
          health. While highly stimulating activities may offer fleeting joy,
          they can also lead to feelings of emptiness and dissatisfaction once
          the stimulation wears off. This emotional roller-coaster may
          contribute to anxiety, depression, and other mental health issues as
          individuals seek a constant state of pleasure.
        </dl>

        <dt>4: Reduction in Productivity and Focus:</dt>

        <dl>
          A society driven by the pursuit of instant gratification may
          experience reduced productivity and focus. The constant allure of
          these activities could divert attention from academic and professional
          responsibilities, leading to lower overall performance and
          achievements.
        </dl>

        <dt>5: Effects on Physical Health:</dt>

        <dl>
          Certain sources of instant pleasure, like drug and alcohol abuse, can
          have severe consequences on physical health. Substance dependency may
          lead to addiction, cognitive impairments, and long-term health
          problems. Additionally, excessive sedentary behavior associated with
          video games and social media could contribute to health issues related
          to a lack of physical activity.
        </dl>

        <dt>6: Diminishing Traditional Values:</dt>

        <dl>
          In a society where instant pleasure is prioritized, long-standing
          values like patience, perseverance, and delayed gratification may
          diminish. These virtues, crucial for personal growth and societal
          progress, could take a backseat to the allure of immediate
          satisfaction.
        </dl>

        <dt>Conclusion:</dt>

        <dl>
          While the pursuit of instant gratification through drugs, alcohol,
          porn, video games, and social media may initially seem enticing, it is
          essential to remain wary of the long-term consequences on society. The
          potential for escapism, dependency, erosion of meaningful connections,
          mental health challenges, reduced productivity, and physical health
          issues underscores the dangers of a society heavily reliant on these
          highly stimulating activities. Striking a balance between instant
          pleasure and meaningful, fulfilling experiences is essential for
          fostering a resilient and prosperous society that prioritizes holistic
          well-being and genuine connections. Promoting open dialogue and
          informed discussions about the impacts of instant gratification will
          empower society to navigate this complex landscape thoughtfully.
        </dl>
      </p>
    ),

    //- --- 2 A Theoretical Society: Examining the Implications of Habitual Substance Use-----------------------

    2: (
      <p>
        <dt>
          A Theoretical Society: Examining the Implications of Habitual
          Substance Use
        </dt>
        <dl>July 27, 2023</dl>
        <br />

        <dt>Introduction:</dt>

        <dl>
          Picture a society where habitual use of alcohol, THC edibles,
          marijuana, and psychedelic substances is the norm. While this concept
          may intrigue some, it is essential to critically analyze the possible
          consequences such a society may face. This blog post will explore the
          hypothetical evolution of this society, considering both positive and
          negative aspects, while maintaining a skeptical view of its overall
          well-being.
        </dl>

        <dt>1. Initial Attraction and Creativity:</dt>

        <dl>
          In the early stages, a society with regular mood-altering substance
          use may appear alluring. People might experience increased creativity,
          introspection, and euphoria. Advocates might argue that these
          substances could enhance artistic expression and foster diverse
          perspectives, resulting in a flourishing cultural environment.
        </dl>

        <dt>2. Escapism and Dependence:</dt>

        <dl>
          As habitual use becomes commonplace, individuals might increasingly
          rely on these substances to cope with life's challenges. While some
          may find temporary relief or escape, depending on mood-altering
          substances can have significant implications for mental health and
          emotional well-being. Seeking escapism from reality could lead to the
          neglect of essential responsibilities, resulting in social
          disconnection and declining productivity.
        </dl>

        <dt>3. Fragmentation of Society:</dt>

        <dl>
          Over time, a divide may emerge between those who engage in these
          substances and those who abstain. Such fragmentation could lead to
          social divisions, with potential stigmatization of non-participants.
          Disagreements over substance policies and resource allocation may
          further strain social cohesion, leading to feelings of isolation among
          different groups.
        </dl>

        <dt>4. Reduced Productivity and Economic Impact:</dt>

        <dl>
          With habitual substance use becoming pervasive, overall productivity
          in society may decline. A workforce with high rates of dependence
          might struggle to maintain efficiency and global competitiveness.
          Consequently, this could lead to economic consequences, affecting the
          country's stability and growth.
        </dl>

        <dt>5. Deterioration of Public Health:</dt>

        <dl>
          Frequent mood-altering substance use could also negatively impact
          public health. Issues like substance abuse disorders, mental health
          challenges, and physical ailments may become prevalent, burdening
          healthcare systems and exhausting medical resources.
        </dl>

        <dt>6. Impairment of Innovation and Critical Thinking:</dt>

        <dl>
          Although initially perceived to enhance creativity and unique
          perspectives, excessive substance use might ultimately hinder genuine
          innovation and critical thinking. Over time, society may come to rely
          on altered states of mind as a substitute for authentic inspiration,
          hindering progress and intellectual development.
        </dl>

        <dt>Conclusion:</dt>

        <dl>
          In the theoretical society characterized by habitual use of alcohol,
          THC edibles, marijuana, and psychedelic substances, there are both
          positive and negative consequences to consider. While some might
          emphasize the potential for creativity and cultural expression,
          skepticism is crucial when evaluating the long-term well-being of such
          a society. The allure of mood-altering substances may initially
          captivate, but the eventual decline of productivity, public health,
          and social cohesion underscores the importance of responsible and
          informed discussions about the role of these substances in any
          society. Striking a balance between individual freedom and collective
          well-being is essential for ensuring the health and stability of any
          community.
        </dl>
      </p>
    ),

    //------ 3 Evaluating Alcohol vs. THC Edibles: Assessing Societal and Health Implications

    3: (
      <p>
        <dt>
          Evaluating Alcohol vs. THC Edibles:Assessing Societal and Health
          Implications
        </dt>
        <dl>July 26, 2023</dl>

        <dt>Introduction:</dt>

        <dl>
          In contemporary society, the prevalence of alcohol and THC edibles
          consumption has increased, often associated with social gatherings and
          recreational use. However, a comprehensive examination of both
          substances necessitates a critical assessment of their potential
          consequences on society and individual well-being. This blog post aims
          to shed light on the societal and health implications of alcohol and
          THC edibles, adopting a skeptical perspective towards their alleged
          benefits.
        </dl>

        <dt>1. Alcohol: Societal and Health Concerns</dt>

        <dl>
          Alcohol, deeply embedded in human culture, can overshadow its negative
          effects due to widespread acceptance. At a societal level, alcohol
          consumption is linked to various issues such as increased violence,
          accidents, and impaired judgment. The World Health Organization (WHO)
          reports that alcohol contributes to around 3 million deaths annually,
          becoming a significant cause of global morbidity and mortality.
          Source:{" "}
          <a
            href="https://www.who.int/news-room/fact-sheets/detail/alcohol"
           
          >
            World Health Organization
          </a>
          Concerning health, excessive alcohol use is associated with a range of
          medical conditions including liver disease, cardiovascular problems,
          and elevated cancer risks. Furthermore, alcohol dependency and
          addiction can lead to adverse personal and societal consequences,
          affecting relationships, productivity, and overall well-being.
        </dl>

        <dt>2. THC Edibles: Weighing the Risks</dt>

        <dl>
          THC edibles, containing the psychoactive compound from marijuana, have
          surged in popularity due to their discreet nature and prolonged
          effects. However, their increased availability has raised concerns
          regarding potential adverse outcomes. At a societal level, THC
          edibles' potency and delayed onset can result in unpredictable
          reactions, making regulation and safety challenging. Accidental
          overdoses, particularly among inexperienced users, have become a cause
          for concern. Additionally, a study published in JAMA Pediatrics
          revealed that the legalization of marijuana edibles in some regions
          has led to an increase in pediatric marijuana exposures, posing risks
          to young children. Source:{" "}
          <a
            href="https://jamanetwork.com/journals/jamapediatrics/fullarticle/2737638"
          
          >
            Wang, G. S., Le Lait, M. C., Deakyne, S. J., Bronstein, A. C.,
            Bajaj, L., & Roosevelt, G. (2019)
          </a>
          Regarding health, the long-term effects of THC edibles remain
          partially understood. While some proponents advocate their therapeutic
          potential for specific medical conditions, robust scientific evidence
          is limited. Regular consumption of THC edibles may lead to cognitive
          impairment, respiratory issues, and potential addiction concerns.
        </dl>

        <dt>Conclusion:</dt>

        <dl>
          When comparing alcohol and THC edibles, it is evident that both
          substances carry significant societal and health implications. While
          alcohol's long-standing cultural presence is associated with societal
          issues and health problems, THC edibles present unique challenges
          related to safety, regulation, and pediatric exposure. To foster an
          informed and balanced discussion, skepticism towards the purported
          benefits of both alcohol and THC edibles is crucial. Acknowledging
          their potential risks and negative impacts is essential for making
          responsible choices and implementing evidence-based policies to
          protect public health and well-being. Encouraging education and open
          dialogue about substance use will contribute to cultivating a
          healthier and more informed society.
        </dl>
      </p>
    ),

    //-- 4 Assessing the Drawbacks: An In-Depth Examination of Marijuana Smoking-----------------

    4: (
      <p>
        <dt>
          Assessing the Drawbacks: An In-Depth Examination of Marijuana Smoking
        </dt>
        <dl>July 27, 2023</dl>

        <dt>Introduction</dt>

        <dl>
          The legalization of marijuana for medical and recreational purposes
          has sparked widespread discussions about its benefits. However, a
          comprehensive understanding requires an equally critical evaluation of
          the potential downsides associated with smoking marijuana. This blog
          post will explore the drawbacks of marijuana use, drawing on credible
          research to shed light on its implications.
        </dl>

        <dt>Cognitive Impairment</dt>

        <dl>
          One of the primary concerns linked to marijuana smoking is its impact
          on cognitive function. Studies have consistently shown that prolonged
          use, especially during adolescence, can lead to lasting cognitive
          deficits. Research published in the Journal of the American Medical
          Association (JAMA) found that long-term marijuana use is associated
          with a decline in IQ and memory retention, adversely affecting overall
          cognitive abilities. Source:{" "}
          <a href="https://www.pnas.org/content/109/40/E2657">
            Meier, M. H., Caspi, A., Ambler, A., Harrington, H., Houts, R.,
            Keefe, R. S. E., … & Moffitt, T. E. (2012)
          </a>
        </dl>

        <dt>Respiratory Health Risks</dt>

        <dl>
          Inhaling marijuana smoke may pose risks to the respiratory system,
          similar to smoking tobacco. The American Lung Association cautions
          that marijuana smoke contains harmful toxins and carcinogens, which
          can irritate the lungs and lead to chronic bronchitis or other
          respiratory issues. Additionally, chronic use may elevate the risk of
          respiratory infections. Source:{" "}
          <a href="https://www.lung.org/quit-smoking/smoking-facts/marijuana-and-lung-health">
            American Lung Association
          </a>
        </dl>

        <dt>Potential for Addiction and Dependency</dt>

        <dl>
          While some argue that marijuana is less addictive than other
          substances, it is not entirely devoid of the potential for dependency.
          The National Institute on Drug Abuse (NIDA) reports that around 9% of
          marijuana users develop a cannabis use disorder, with a higher risk
          (about 17%) among those who begin using during their teenage years.
          Regular and heavy use can lead to tolerance and withdrawal symptoms.
          Source:{" "}
          <a href="https://www.drugabuse.gov/publications/research-reports/marijuana/marijuana-addictive">
            National Institute on Drug Abuse
          </a>
        </dl>

        <dt>Impaired Driving</dt>

        <dl>
          Marijuana's psychoactive properties can impair motor skills and
          reaction times, making it hazardous to drive under its influence.
          Studies, such as those published in the journal Addiction, have found
          that marijuana significantly increases the risk of car accidents, akin
          to alcohol use. Source:{" "}
          <a href="https://academic.oup.com/epirev/article/34/1/65/552442">
            Li, M. C., Brady, J. E., DiMaggio, C. J., Lusardi, A. R., & Tzong,
            K. Y. (2012)
          </a>
        </dl>

        <dt>Conclusion</dt>

        <dl>
          While the potential benefits of marijuana continue to be widely
          discussed, it is essential to weigh these against the potential
          drawbacks associated with smoking marijuana. Research consistently
          highlights negative effects on cognitive function, respiratory health,
          and the risk of dependency. Additionally, marijuana use can impair
          driving abilities, raising significant safety concerns. As we delve
          into the complexities of marijuana use, a critical and evidence-based
          approach will lead to informed decision-making and comprehensive
          discussions.
        </dl>
      </p>
    ),

    //---- 5  Challenging Assumptions: The Complex Relationship Between Marijuana Use and Habitual Consumption------

    5: (
      <p>
        <dt>
          Challenging Assumptions: The Complex Relationship Between Marijuana
          Use and Habitual Consumption
        </dt>
        <dl>July 27, 2023</dl>

        <dt>Introduction:</dt>

        <dl>
          The subject of marijuana use and its potential connection to habitual
          consumption of more dangerous substances has been a contentious issue
          for a considerable period. Often referred to as the “gateway drug”
          theory, it posits that marijuana use may serve as a catalyst for
          individuals to experiment with and subsequently develop dependencies
          on harder drugs. This article takes a skeptical approach, critically
          examining the existing research surrounding marijuana's gateway
          properties and its role in fostering habitual use patterns.
        </dl>

        <dt>1. The Gateway Drug Theory: Evaluating the Evidence</dt>

        <dl>
          The purported link between marijuana and progression to more potent
          drugs has been a subject of public concern for some time. However, the
          veracity of this association demands meticulous scrutiny. While
          initial studies appeared to lend support to the gateway theory,
          subsequent investigations have questioned the validity of such claims.
        </dl>

        <dt>2. Unraveling the Multifaceted Factors:</dt>

        <dl>
          A comprehensive analysis demands a broader consideration of the
          complex factors influencing drug use behaviors. While marijuana use
          may intersect with subsequent drug experimentation, it is vital to
          acknowledge the multitude of societal, psychological, and
          environmental elements at play. Factors such as peer influences,
          socio-economic backgrounds, mental health conditions, and
          accessibility to drugs all contribute to an individual's drug journey.
          Ascribing sole responsibility to marijuana may oversimplify a
          profoundly intricate issue.
        </dl>

        <dt>3. Examining Habitual Marijuana Use:</dt>

        <dl>
          Acknowledging the appeal of marijuana's psychoactive effects, some
          individuals develop a proclivity for repeated use. It is essential to
          address the phenomenon of habitual consumption without underestimating
          its significance. Studies have revealed that approximately 9% of
          marijuana users reported daily or near-daily use, and 41% reported
          usage at least once a month. While this highlights the existence of
          habitual use, it is crucial to juxtapose this with the larger
          population of users who do not follow such patterns.
        </dl>

        <dt>4. Analyzing Data Insights:</dt>

        <dl>
          Delving further into the data, it becomes evident that the majority of
          marijuana users exhibit infrequent usage patterns. Among young adults
          aged 19-22, a substantial 68% of past-year users reported consuming
          marijuana on 10 or fewer occasions during that period. These figures
          challenge the notion that habitual marijuana use is ubiquitous among
          users.
        </dl>

        <dt>5. Individual Variability:</dt>

        <dl>
          To cultivate a nuanced understanding, it is imperative to recognize
          the inherent individuality in drug use behaviors. While some users may
          engage in habitual consumption, this does not reflect the experiences
          of all individuals. Biological, psychological, and social distinctions
          among users contribute to diverse reactions and behaviors concerning
          marijuana use.
        </dl>

        <dt>Conclusion:</dt>

        <dl>
          In summary, the contentious issue of marijuana's role as a gateway
          drug and its association with habitual consumption demands an
          objective evaluation. While early research appeared to support the
          gateway theory, subsequent investigations and a closer examination of
          data have prompted skepticism. The complex interplay of various
          factors influencing drug behaviors necessitates a nuanced perspective.
          By approaching the topic with a critical lens, we can dispel
          preconceived notions and delve into the intricacies of marijuana use.
          Advancing our understanding through comprehensive research and
          informed discussions will facilitate evidence-based policies and
          interventions that prioritize individual well-being and public health.
          Ultimately, it is essential to foster open dialogue, acknowledge the
          complexity of drug use behaviors, and promote a balanced approach to
          addressing this multifaceted issue.
        </dl>
      </p>
    ),

    //--- 6 Let's ask Chat GPT: The Complex Relationship Between Marijuana Use and Habitual Consumption -----------

    6: (
      <p>
        <dt>
          Let's ask Chat GPT: The Complex Relationship Between Marijuana Use and
          Habitual Consumption
        </dt>
        <dl>July 27, 2023</dl>

        <dt>
          According to the data you were trained on, is a person more likely to
          be unproductive if they regularly consume marijuana? Expand on this in
          detail.
        </dt>

        <dl>
          As an AI language model, I can provide information based on the data I
          was trained on. However, it's important to note that my responses are
          based on general knowledge and patterns, and may not accurately
          reflect every individual's experience. Additionally, societal
          perspectives and scientific understanding can evolve over time. With
          that in mind, I'll provide an overview based on the information
          available up until September 2021. The impact of marijuana on
          productivity is a complex topic, and it can vary depending on several
          factors, including an individual's physiology, frequency and dosage of
          marijuana use, and personal circumstances. While some individuals may
          experience decreased productivity when using marijuana, others may not
          be significantly affected or may even report increased productivity in
          certain tasks or creative pursuits. Marijuana contains compounds
          called cannabinoids, the most notable being tetrahydrocannabinol (THC)
          and cannabidiol (CBD). THC is primarily responsible for the
          psychoactive effects of marijuana, while CBD is non-intoxicating and
          is believed to have potential therapeutic properties.
        </dl>
        <dl>
          Marijuana's effect on productivity can be influenced by various
          factors:
        </dl>
        <dl>
          1. Short-term effects: Marijuana can affect cognitive functions such
          as memory, attention, and reaction time, particularly when used in
          high doses or in inexperienced users. These acute effects may impair
          performance in tasks that require concentration, coordination, or
          quick decision-making.
        </dl>
        <dl>
          2. Chronic use: Frequent or heavy marijuana use can potentially impact
          motivation and overall productivity over time. Some individuals may
          experience a lack of drive or difficulty initiating and completing
          tasks. However, the extent to which chronic marijuana use affects
          productivity can vary greatly among individuals.
        </dl>
        <dl>
          3. Individual variability: People react differently to marijuana due
          to variations in brain chemistry, genetics, and other personal
          factors. Some individuals may be more sensitive to the impairing
          effects of marijuana, while others may adapt or experience different
          effects.
        </dl>
        <dl>
          4. External factors: Productivity is influenced by numerous external
          factors, including an individual's overall physical and mental health,
          work environment, stress levels, and personal motivation. These
          factors can interact with marijuana use and contribute to variations
          in productivity outcomes.
        </dl>
        <dl>
          It's important to emphasize that productivity is a multifaceted
          concept and cannot be solely attributed to marijuana use. Many
          productive individuals use marijuana responsibly and in moderation
          without significant negative impact on their overall output or
          achievements. It's also worth noting that there are successful
          professionals in various fields who consume marijuana recreationally
          or for medical purposes. However, if you're concerned about the impact
          of marijuana on your productivity or the productivity of someone you
          know, it may be helpful to consider individual circumstances and seek
          advice from medical professionals or addiction specialists who can
          provide personalized guidance based on up-to-date research and
          clinical expertise.
        </dl>

        <dt>
          Would you say regularly consuming marijuana makes someone more
          productive or less productive?
        </dt>
        <dl>
          1. Based on the information available up until September 2021, it is
          generally believed that regularly consuming marijuana is more likely
          to have a negative impact on productivity rather than enhancing it.
          While some individuals may report increased productivity in certain
          tasks or creative pursuits while using marijuana, the overall
          consensus is that regular marijuana use can have adverse effects on
          productivity for several reasons: 
          <br /> <br />
          2. Impaired cognitive function:
          Marijuana use, particularly in high doses or over an extended period,
          can impair cognitive functions such as memory, attention, and
          problem-solving abilities. These impairments can hinder productivity
          in tasks that require concentration, critical thinking, or complex
          decision-making. 
          
          <br /> <br />3. Motivational effects: Regular marijuana use can
          potentially affect an individual's motivation and drive. Some users
          may experience decreased ambition, difficulty initiating and
          completing tasks, and a reduced sense of productivity. This lack of
          motivation can hinder overall productivity and goal achievement. 
          
          <br /> <br />4.
          Impact on work performance: Jobs that require coordination, precision,
          or safety precautions may be negatively affected by marijuana use.
          Impaired motor skills, slowed reaction times, and reduced attention to
          detail can compromise performance in such tasks, leading to decreased
          productivity and potential safety risks.
          
          <br /> <br /> 5. Variability among
          individuals: It's important to acknowledge that people react
          differently to marijuana due to variations in brain chemistry,
          genetics, and personal factors. While some individuals may be more
          resilient to the potential negative effects of marijuana on
          productivity, others may be more susceptible, experiencing a greater
          decline in their ability to perform tasks efficiently and effectively.
          However, it's crucial to consider that productivity is influenced by
          numerous factors beyond marijuana use, such as an individual's overall
          physical and mental health, work environment, stress levels, and
          personal motivation. It is possible for someone to maintain or even
          enhance their productivity while using marijuana, depending on their
          specific circumstances and how they manage their use. It's important
          to note that scientific research on the effects of marijuana,
          including its impact on productivity, is an active area of study. New
          findings and evolving societal perspectives may contribute to a more
          nuanced understanding of this topic in the future. If you have
          concerns about your own or someone else's productivity in relation to
          marijuana use, it is advisable to seek personalized advice from
          medical professionals or addiction specialists who can provide
          up-to-date information and tailored guidance.
        </dl>
      </p>
    ),

    7: (
      <p>
        <dt>Let's ask Chat GPT for a pro and anti argument for marijuana</dt>

        <dl>
          “I can provide you with an anti-marijuana argument that reflects some
          concerns raised by individuals who oppose its use:”
        </dl>

        <dl>
          Health Risks: Marijuana use can have negative health effects,
          especially when used in excess or by vulnerable populations. It can
          impair cognitive function, memory, and coordination, potentially
          leading to accidents or impaired performance in daily activities.
          Long-term use has been associated with respiratory issues, mental
          health problems, and addiction. These health risks should be taken
          seriously and considered when evaluating the overall impact of
          marijuana use. Gateway to Harder Drugs: Some argue that marijuana
          serves as a gateway drug, potentially leading users to experiment with
          and develop dependencies on more dangerous substances. While the
          gateway theory is debated and not universally accepted, there are
          studies suggesting an association between marijuana use and subsequent
          use of harder drugs. This concern underscores the importance of
          considering the potential risks and unintended consequences of
          marijuana use. Negative Impact on Youth: Adolescents' brains are still
          developing, and early marijuana use may have detrimental effects on
          cognitive function and mental health. There is evidence linking
          marijuana use during adolescence to increased risks of developing
          substance abuse disorders, mental health disorders, and impaired
          educational outcomes. Protecting the well-being and future prospects
          of young individuals is a priority for those who advocate against
          marijuana use. Impaired Driving: Marijuana use can impair judgment,
          reaction time, and motor skills, making it dangerous to operate
          vehicles or machinery while under the influence. This poses risks not
          only to the user but also to others on the road. Ensuring public
          safety and preventing impaired driving accidents are concerns that are
          often raised in discussions surrounding marijuana legalization. Legal
          and Social Implications: Critics argue that the legalization of
          marijuana for recreational use can have societal consequences. They
          raise concerns about increased availability, potential normalization
          of drug use, and challenges in regulating production and distribution.
          They also question the impact on public health systems, law
          enforcement, and workplace safety. It's important to note that this
          response presents an anti-marijuana argument but does not represent a
          comprehensive analysis of the topic. Views on marijuana can vary, and
          it's essential to consider multiple perspectives, scientific research,
          and societal contexts when forming an informed opinion.
        </dl>

        <dl>
          “Here's a pro-marijuana argument that reflects some of the viewpoints
          expressed by proponents of marijuana legalization and recreational
          use:”
        </dl>

        <dl>
          Personal Freedom and Autonomy: Advocates for marijuana argue that
          adults should have the right to make decisions about their own bodies
          and recreational activities, as long as they do not harm others. They
          believe that individuals should be allowed to choose whether or not to
          use marijuana, similar to the way alcohol and tobacco use is
          regulated. Medicinal Benefits: Marijuana has been shown to have
          potential therapeutic benefits for various medical conditions, such as
          chronic pain, nausea, muscle spasms, and certain neurological
          disorders. Proponents argue that denying access to a potentially
          effective and safer alternative for those in need is unjust. They
          emphasize the importance of allowing individuals to use marijuana as a
          medicinal option under appropriate medical supervision and legal
          frameworks. Economic Opportunities: The legalization and regulation of
          marijuana can create economic benefits, including job creation, tax
          revenue, and opportunities for businesses in the legal cannabis
          industry. Proponents argue that the industry can stimulate local
          economies, generate tax revenue for public services, and provide
          opportunities for entrepreneurship and innovation. Harm Reduction:
          Supporters of marijuana legalization argue that regulating and taxing
          marijuana can help reduce the harms associated with illicit drug
          markets. They contend that legalizing marijuana can undermine the
          black market, ensuring quality control, consumer safety, and age
          restrictions. Additionally, they argue that law enforcement resources
          can be better allocated toward addressing more serious crimes. Social
          Justice and Racial Disparities: Many proponents of marijuana
          legalization highlight the disproportionate impact of marijuana
          prohibition on marginalized communities. They argue that criminalizing
          marijuana has led to over-policing, racial disparities in arrests and
          convictions, and the perpetuation of systemic injustices. Legalization
          and regulation can help address these social justice issues by
          reducing unnecessary arrests and allowing for expungement of past
          convictions.
        </dl>
      </p>
    ),

    //   article8---------------------------Marijuana is OK because it is natural?-------------------------------------------------
    8: <p>

<dt>Marijuana is OK because it is natural?</dt><br />
May 21, 2023 <br /><br /><br />

The argument that marijuana is good because it is natural is often used to promote its use and suggest that it is inherently safe or beneficial. However, this argument can be flawed for several reasons:
<br /><br />
- Appeal to nature fallacy: The idea that something is good or better simply because it is natural is an example of an “appeal to nature” fallacy. It assumes that natural substances are always safe and superior to synthetic or artificial ones. However, this reasoning overlooks the fact that many natural substances can be harmful or have negative effects on health. For example, poisonous plants and toxic substances exist in nature.
<br /><br />
- Natural does not guarantee safety: While marijuana comes from a plant, it doesn't automatically mean it is completely safe or without risks. The effects of marijuana on the body and mind can vary, and there are potential health risks associated with its use. It contains psychoactive compounds, such as THC, which can have both short-term and long-term effects on cognition, memory, coordination, and mental health. Like any substance, marijuana should be used responsibly, taking into account potential risks and individual factors.
<br /><br />
- Manipulation through cultivation: The argument that marijuana is natural may overlook the fact that the plant has been selectively bred and manipulated by humans over centuries to enhance its psychoactive properties. Modern strains of marijuana are often significantly more potent than their wild counterparts. This raises questions about whether the argument still holds true when discussing highly potent marijuana products.
<br /><br />
- Synthetic alternatives: The notion that natural substances are inherently better can disregard the potential benefits of synthetic alternatives. In some cases, synthetic compounds may offer advantages such as standardized dosages, predictable effects, and reduced impurities. It is important to consider the overall safety, efficacy, and quality of a substance, regardless of whether it is natural or synthetic.
<br /><br />
When evaluating the merits of marijuana, it is more appropriate to consider scientific research, medical evidence, and a nuanced understanding of the risks and benefits associated with its use, rather than relying solely on the argument that it is natural.


    </p>,

    //   article9----------------Let's ask Chat GPT's opinion of marijuana--------------------------------------------------------------
    9: <p>

      <dt>Let's ask Chat GPT's opinion of marijuana</dt> <br />
      May 21, 2023 <br /><br /><br />

<dt>What is your opinion on recreational use of marijuana?</dt><br />
“The opinions on the recreational use of marijuana vary greatly among individuals and societies. Some people support the legalization and recreational use of marijuana for various reasons, while others have concerns or objections.
<br /><br />
Proponents of recreational marijuana argue that it can have positive effects, such as relaxation, stress reduction, pain relief, and enjoyment. They may also point out potential economic benefits, such as tax revenue and job creation from the legal cannabis industry. Additionally, they may argue that marijuana is less harmful than other substances, such as alcohol or tobacco, and that adults should have the freedom to make choices regarding their own recreational activities.
<br /><br />
On the other hand, critics of recreational marijuana express concerns about potential negative consequences. These concerns may include potential health risks, impaired cognitive function, increased risk of addiction, and potential negative effects on mental health. Critics may also raise concerns about the potential for increased use among vulnerable populations, such as adolescents, and the impact on public safety, including impaired driving.
<br /><br />
It's important to note that laws and public attitudes towards recreational marijuana vary across different countries and regions. Some places have legalized or decriminalized recreational marijuana, while others have stricter regulations or consider it illegal.
<br /><br />
Ultimately, individual perspectives on the recreational use of marijuana depend on personal beliefs, cultural values, scientific understanding, and the specific context in which the discussion takes place. It is crucial to consider multiple viewpoints and examine available evidence when forming an opinion on this topic.”


    </p>,

    //   article10--------------------------------5 forms of peer pressure that make people do drugs----------------------------------------------
    10: <p>


      <dt>5 Forms of Peer Pressure that Make People do Drugs</dt><br />
      
      December 23, 2022 <br /><br /><br />

People being peer-pressured into taking drugs for the first time, are taking drugs for approval, to gain respect, to be cool, for an increase of status, or to avoid ridicule from their peer group.
<br /><br />
Peer pressure takes many forms. Here are a few scenarios where a person ends up doing drugs due to pressure from others:
<br /><br />
1. Peer pressure from a friend trying to get their best friend involved so they can share this new life-changing reality together. In this case the pressure is overwhelming as it could mean the end of the relationship if the friend declines to partake. 
<br /><br />
2. Peer pressure could be in the form of the classic boy friend making the girl friend (or vice versa) to take a hit, to show that they love them. In this case the pressure is even more overwhelming than the best friend situation, because of the intoxication of love. In cases like these it is possible that the 'lover' is simply a drug dealer trying to get a new customer.
<br /><br />
3. Peer pressure is at a very high level when someone enters a new group, in order to elevate his reputation and gain some immediate respect it is not uncommon to take drugs or commit crimes, all in an effort to appear cool. This is most common in middle schools, high schools, colleges and gangs.
<br /><br />
4. Peer pressure can also manifest in another unfortunate circumstance where that one friend in a peer group who doesn't do drugs, is still holding out but yet still is in the group and hangs out with them when they are doing drugs, and who may after years of receiving mocking looks and snide comments may give in and start using, in order to feel like part of the group again. This could be a social group, or it could be a work group.
<br /><br />
5. Another seldom considered but potent and insidious form of peer pressure is pressure from a medical doctor to a patient to take a potentially addictive medication, be it a pain-killer, sedative, etc., when the patient really doesn't want it, but doesn't voice their disagreement due to the over-powering influence the doctor has on them. The relationship between doctors and the pharmaceutical industry is very tight and doctors are known to 'push pills' on patients.



    </p>,

    //   article11------------------Drugged driving------------------------------------------------------------

    11: <p>

<dt>Drugged driving</dt><br />
December 23, 2022 <br /><br /><br />

We've heard of drunk driving, yes.
<br /><br />
But have you ever heard of 'drugged driving'?
<br /><br />
It is aptly named as more and more states are legalizing marijuana. According to Business Insider:
<br /><br />
“21 states and Washington, DC have legalized recreational marijuana. Cannabis is legal for medical purposes in 38 states and DC”
<br /><br />
You might say, well that doesn't mean more people are driving under the influence of substances. But take a look at these numbers from the 2018 National Survey on Drug Use and Health (NSDUH).
<br /><br />
“in 2018, 20.5 million people aged 16 or older drove under the influence of alcohol in the past year and 12.6 million drove under the influence of illicit drugs.”
<br /><br />
So, there are a lot of people on the road under the influences of substances. But, is it as bad as alcohol? Is there anything for us to worry about? After all, marijuana can't be that bad, especially when considering how innocuously it is portrayed in Hollywood films.
<br /><br />
Let's see what the National Institute on Drug Abuse has to say:
<br /><br />
“Research studies have shown negative effects of marijuana on drivers, including an increase in lane weaving, poor reaction time, and altered attention to the road. Use of alcohol with marijuana makes drivers more impaired, causing even more lane weaving. Some studies report that opioids can cause drowsiness and impair thinking and judgment. Other studies have found that being under the influence opioids while driving can double your risk of having a crash.”
<br /><br />
In light of this information, it is clear that one is taking a risk when they get behind the wheel while under the influence of marijuana or other drugs. But worse than that, they are also taking a risk by simply being on the road because there are other drivers who are impaired.
<br /><br />
But is anyone really getting harmed from drugged drivers?
<br /><br />
The National Institute on Drug Abuse cited in an article: “According to the Governors Highway Safety Association, 43.6 percent of fatally injured drivers in 2016 tested positive for drugs and over half of those drivers were positive for two or more drugs.
<br /><br />
This should be a sobering number for those who think drugs are harmless and driving while 'tweaked' is only mildly objectional.
<br /><br />
Drivers should be able to drive on the road without fear of a drugged driver or drunk driver crashing into them, causing injury to their person, vehicle or family. It is a sad remark on our society that the population can be this irresponsible.
<br /><br /><br />
<dt>What is the solution?</dt><br />
Unfortunately, making drunk or drugged driving illegal does not stop people from doing it, because drugged driving is already illegal, yet people still do it, as they do with alcohol.
<br /><br />
As for solving the problem, surely you can increase penalties for DUI's and DWI's but punishing man into being good may not be the best answer. A better solution may be education-based. As it is likely many who are drugged drivers feel there is no problem, and may have misconceptions that need debunking, additionally they are probably not aware of the statistics previously mentioned.

   

    </p>,

    //   article12-----------------------------To be drugged-------------------------------------------------
    
    12: <p>
      
      <dt>To be drugged</dt><br />
      December 23, 2022 <br /><br /><br />

    If you think about the meaning of the word 'drugged', according to Oxford it is defined as: (of a person) unconscious or in a stupor as a result of taking or being given a drug.<br /><br />

    It is strange that a population would demand fervently to be use substances that often result in this condition, whether by use of legal or illegal substances. To be in a 'stupor' or unconscious is a dangerous condition to be in, for anyone, whether in the safety of their home or in a night club.<br /><br />
    
    Obviously, people don't do drugs for the side effect of being unconscious or being in a stupor, but it can happen to them nonetheless, depending on the type of substance used, and the amount taken. Sedatives, opioids, marijuana, hallucinogens and depressants like alcohol can all cause this effect.<br /><br />
    
    Clearly, to those who consume, the consequence of losing bodily control and mental faculties is worth the reward of the enjoyable sensations from the substance. The risk is worth the reward, otherwise people would not consume.<br /><br />
    
    But if one is cautious, and avoids consuming substances in high risk environments like night clubs, and secludes themselves to consumption at home, where they cannot be victimized, is one truly safe? What's the worst that could happen? Waking up late the next day for work? Or sending embarrassing text messages while high?<br /><br />
    
    And what of alcohol, people have been drinking themselves into stupors for thousands of years.<br /><br />
    
    Well here it is. One of the major risks of consuming substances is the loss of judgement.<br /><br />
    
    What is keeping someone from getting high at home, and during their high, deciding to get into a vehicle and causing an accident?<br /><br />
    
    The same could be said for alcohol.<br /><br />
    
    There are no guarantees of safe behavior from people who are no longer themselves.<br /><br />
    
    What is keeping a person high on marijuana from getting into their car to go get some snacks<br /><br />
    
    What is keeping a drinker from leaving their house, getting in their car to go buy another bottle?<br /><br />
    
    The answer is: nothing.<br /><br />
    
    When judgement is impaired, so is reality. And there is nothing more dangerous than a person not in the present reality. Dangerous for himself, and for others.
    <br /><br />
    And this is not just a problem for drug abusers. Recreational users are at risk just the same. It only takes a small amount for a substance so start effecting one's judgement.
    <br /><br />
    And driving under the influence of substances is definitely illegal and can result in a DUI on the offender.
    <br /><br />
    According to the National Highway Traffic Safety Administration:
    <br /><br />
    “Every day, about 32 people in the United States die in drunk-driving crashes — that's one person every 45 minutes. In 2020, 11,654 people died in alcohol-impaired driving traffic deaths — a 14% increase from 2019. These deaths were all preventable.”
    <br /><br />
    There is no mention of substances outside of alcohol, but here are some interesting facts from the National Institute on Drug Abuse:
    <br /><br />
    - In 2018, 20.5 million people aged 16 or older drove under the influence of alcohol in the past year and 12.6 million drove under the influence of illicit drugs.
    <br /><br />
    - It's hard to measure how many crashes are caused by drugged driving, but estimates show that almost 44 percent of drivers in fatal car crashes tested positive for drugs.
    <br /><br />
    - Driving under the influence of marijuana, opioids and alcohol can have profound effects on driving. <br /><br /><br />

   <dt>What is the solution?</dt> <br />
    In spite of the risks it seems that man has been seeking out and consuming mind-altering substances for as long as he has been alive. Just because it has always been that way does not mean that it should be that way, or that it is hopeless to change. Given enough time from learning from mistakes, study, and introspection, man has a unique ability to evolve his behaviors, optimize and become a better man. But that day may be far off in the future. What can we do in the now?
    <br /><br />
    Minimally, a campaign to educate the population that:<br /><br />
    
    1. Substances do in fact impair judgement, and the consequences<br /><br />
    
    2. It is not just a problem with alcohol<br /><br />
    
    3. Promotes designated drivers
    
    
    </p>,

    //   article13-----------------------------Thoughts on gun control-------------------------------------------------
    13: <p>


      <dt>Thoughts on gun control</dt><br />

      December 22, 2022 <br /><br /><br />
      
    <dt>An Argument Against Gun Control</dt><br />

    Will making firearms illegal prevent people from committing crimes with firearms?<br /><br />
    
    The argument is that if there are laws in place that make it illegal to own firearms that people will not be able to acquire said firearms.<br /><br />
    
    This argument is based on the following premise: the citizenry will follow laws related to firearms and their banning.<br /><br />
    
    This argument can only be true if all people follow the law.<br /><br />
    
    If all people followed laws banning guns, then there would be no crimes committed with guns as everyone is following that law.<br /><br />
    
    Criminals are by definition: people who don't follow the law.<br /><br />
    
    Criminals cannot be expected to follow any gun control law, as they by definition do not follow the law. Gangs, criminals and criminal organizations will still find a way to get their guns, completely regardless of any legislation, because once again they by definition do not follow the law. <br /><br />
    
   
    <dt>Conclusion:</dt><br />
    
    Banning guns cannot prevent criminals from acquiring guns and committing crimes with them. <br /><br />
    
    But will banning guns prevent would-be criminals from using them?<br /><br />
    
    
    <dt>An Argument for Gun Control:</dt><br />
    
    By having guns legally available and omnipresent their ease of access allows guns to be used more frequently in crimes than if guns were illegal and had to be acquired illegally.<br /><br />
    
    In other words does simply having the ease of access increase the likelihood that someone will use a gun in a crime and harm someone. And if they guns were illegal would it be too much trouble to go get one illegally and thus, the crime will never have happened due to the inconvenience of having to get an illegal weapon.<br /><br />
    
    The argument is only correct if the person gives up and doesn't follow through with his crime due to the inconvenience of having to acquire the weapon illegally (we still assume there will always be a black market where illegal goods can be purchased, regardless of what laws exist).<br /><br />
    
    A person not serious about committing a crime is not likely to go through the motions of acquiring a firearm illegally. Thus, by having fewer guns around, he is less likely to use them in a crime.<br /><br />
    
    A person serious about a crime will not be stopped by the inconvenience of having to acquire a firearm illegally, and if not able to acquire a gun he will use a different weapon (knives, poison, explosives), because he is serious about committing his crime.<br /><br />
    
    
    <dt>Conclusion</dt><br />
    
    By having no guns available, gun crime statistics will lessen because the unserious will be balked at the difficulty of acquiring one.<br /><br />
    
    By having no guns available, people who are serious about committing crime will not be stopped, they will find a way.<br /><br />
    
    An Argument Against Gun Control<br /><br />
    
    If guns are banned, gun crime will lessen, as shown above, the only groups of individuals who will possess guns will be:<br /><br />
    
    - The Government
    - Criminals
    - Law-abiding ethical citizens will not have access to guns.
    
    Therefore, law-abiding citizens are at the mercy of governments and criminals, with no way to effectively defend themselves, be it a home invasion, or a tyrannical regime.<br /><br />
    
    <dt>Conclusion</dt><br />
    
    By having no guns available, law-abiding citizens can be taken advantage of, harmed or killed by criminals or tyrannical governments.<br /><br />
    
    <dt>The Question:</dt><br />
    
    Does the benefit of having lower gun related crime & deaths out-weigh the benefit of having a citizenry that can defend themselves against criminals and or a tyrannical government?<br /><br />
    
    <dt>A flaw in the Logic</dt><br />
    
    'City A' has guns completely band. 'City A' enjoys a very low gun violence rate and general low crime rate. A rough example of this city could be Tokyo, Japan.<br /><br />
    
    'City B' also has guns completely band. However, 'City B', has a much higher general crime rate and a higher gun violence rate than 'City A'. A rough example of this city could be London, England.<br /><br />
    
    How is this possible?
    
    'City B' should have enjoy the same low crime rates as 'City A'. Why not?<br /><br />
    
    What is missing in the equation?<br /><br />
    
    “the individual, human being”.<br /><br />
    
    You could say that the people in A are less violent by nature than the people in B; and conform to law and order more readily. Therefore, there is less crime. Something about the culture in B has them more violent, we don't know the cause. Something about the social mores in B has them more likely to fight and harm, laws or no laws. While in A, it is embedded in their social mores to avoid violence.<br /><br />
    
    <dt>A Different Question</dt><br />
    
    How could passive low-crime 'City A' experience an increase in gun crime if guns were not banned? (due to the convenience factor mentioned earlier). Possible yes, possibly no.<br /><br />
    
    Would aggressive high-crime 'City B' also experience an increase in gun crime if guns were not banned? Probably yes.<br /><br />
    
    If guns became completely legal in both A and B, both are likely to see an increase in gun related crime, but which city would still have less gun related crime when compared?<br /><br />
    
    It would be 'City A'. Again, because of the social mores.<br /><br />
    
    The missing factor in the formula is the people themselves.<br /><br />
    
    Does there exist, a 'City C' which has little or no gun laws, and as well little or no gun related crime? If there does, it would further drive home this conclusion.<br /><br />
    
   <dt>Conclusion</dt><br /> 
    
    Considering the failure of 'City B', the complete solution to gun related crime is not legislation. The solution is to remove the anti-social, violent behavior of people, and replace it with respectful and considerate behavior.  <br /><br />
    
    Shown in our comparison, laws cannot enforce sanity, good behavior and decency to all people. Those have to already be ingrained in the people.<br /><br />
    
    <dt>The Only Real Solution</dt><br />
    
    Legislating the problem away proves to be a lazy, imperfect, and ineffective approach which looks good only on the surface, but upon further inspection it crumbles.<br /><br />
    
    The only real solution involves each and every person on the planet striving to become a better person, dealing with their demons, elevating their social mores to the point where the thought of harming another human being, gun or no gun, would be reprehensible.<br /><br />
    
    Make every person on the planet a better person?<br />
    
    Logistically it seems impossible to pull off or implement such a program. But impossible or not, one must admit that this is the answer to the problem; the problem of gun violence, knife violence, and even crime itself. The truth is always simple, sometimes so simple that it is the easiest to be forgotten.<br /><br />
    
    Gun violence needs no special fix; when ALL forms of crime stems from the same problem: man's inhumanity to man. And it has to be addressed with one person at a time. 
     </p>,
  };

  const processCommand = (input: string) => {
    logEvent(analytics, "command_received", { command: input });

    // Store a record of this command with a ref to allow us to scroll it into view.
    // Note: We use a ref callback here because setting the ref directly, then clearing output seems to set the ref to null.
    const commandRecord = (
      <div
        ref={(el) => (scrollRef.current = el)}
        className="terminal-command-record"
      >
        <span className="terminal-prompt">{terminalPrompt}</span>
        <span>{input}</span>
      </div>
    );

    // Add command to to history if the command is not empty
    if (input.trim()) {
      setHistory([...history, input]);
      setHistoryIndex(history.length + 1);
    }

    // Now process command, ignoring case
    const inputCommand = input.toLowerCase();
    if (!isValidCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">
          <ErrorMessage command={inputCommand} />
        </div>,
      ]);
    } else if (isEchoCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">{commands[inputCommand]}</div>,
      ]);
    } else if (isUtilityCommand(inputCommand)) {
      switch (inputCommand) {
        case "clear": {
          setOutput([]);
          break;
        }
        case "all": {
          // Output all commands in a custom order.
          const allCommandsOutput = ["about", "contact", "blog_posts"].map(
            (command) => (
              <>
                <div className="terminal-heading">{command}</div>
                <div className="terminal-command-output">
                  {commands[command as EchoCommand]}
                </div>
              </>
            )
          );

          setOutput([commandRecord, ...allCommandsOutput]);
          break;
        }
      }
    }
  };

  const getHistory = (direction: "up" | "down") => {
    let updatedIndex;
    if (direction === "up") {
      updatedIndex = historyIndex === 0 ? 0 : historyIndex - 1;
    } else {
      updatedIndex =
        historyIndex === history.length ? history.length : historyIndex + 1;
    }
    setHistoryIndex(updatedIndex);
    return updatedIndex === history.length ? "" : history[updatedIndex];
  };

  const getAutocomplete = (input: string) => {
    const matchingCommands = allCommands.filter((c) => c.startsWith(input));
    if (matchingCommands.length === 1) {
      return matchingCommands[0];
    } else {
      const commandRecord = (
        <div
          ref={(el) => (scrollRef.current = el)}
          className="terminal-command-record"
        >
          <span className="terminal-prompt">{terminalPrompt}</span>
          <span>{input}</span>
        </div>
      );
      setOutput([...output, commandRecord, matchingCommands.join("    ")]);
      return input;
    }
  };

  const focusOnInput = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Tab") {
      // Prevent tab from moving focus
      event.preventDefault();
    }
    inputRef.current?.focus();
  };

  return (
    <div className="terminal-container" tabIndex={-1} onKeyDown={focusOnInput}>
      <div className="terminal-content">
        {banner && <Banner banner={banner} />}
        {welcomeMessage && (
          <WelcomeMessage message={welcomeMessage} inputRef={inputRef} />
        )}
        <TerminalOutput outputs={output} />
        <InputArea
          setOutput={setOutput}
          processCommand={processCommand}
          getHistory={getHistory}
          getAutocomplete={getAutocomplete}
          inputRef={inputRef}
          terminalPrompt={terminalPrompt}
        />
      </div>
    </div>
  );
};

export default Terminal;
